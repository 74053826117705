<template>
  <el-main class="datause-container">
    <div class="inner-container">
      <div class="section-h2">
        <MainContain />
        {{ $t('DataUsage.Data_Usage') }}
      </div>
      <div class="content-section">
        <section class="content-section">
          <div class="section-h3">{{ $t('DataUsage.Foreword') }}</div>
          <div class="content-desc">
            {{ $t('DataUsage.Foreword_Description') }}
          </div>
          <div class="section-h3">
            {{ $t('DataUsage.How_and_Who') }}
          </div>
          <div class="content-desc">
            {{ $t('DataUsage.How_and_Who_Section1') }}
          </div>
          <div class="content-desc">
            <img :src="datausage_pic0" style="width:100%" :alt="howToUseSportData"> 
          </div>          
          <div class="content-desc">
            {{ $t('DataUsage.How_and_Who_Section2') }}
          </div>
          <div class="content-desc">
            {{ $t('DataUsage.Data_Usage_Include') }}
          </div>
          <strong>
            {{ $t('DataUsage.Exercise_Advocacy') }}
          </strong>
          <ul>
            <li>{{ $t('DataUsage.Studies_and_Analyses.Road_Section') }}</li>
            <li>{{ $t('DataUsage.Studies_and_Analyses.Time_Slot') }}</li>
            <li>{{ $t('DataUsage.Studies_and_Analyses.Urban_rural') }}</li>
            <li>{{ $t('DataUsage.Studies_and_Analyses.Exercise_Energy') }}</li>
            <li>{{ $t('DataUsage.Studies_and_Analyses.Tourism_Festival') }}</li>
            <li>{{ $t('DataUsage.Studies_and_Analyses.Fatigue_Recuperation') }}</li>
            <li>{{ $t('DataUsage.Studies_and_Analyses.Sports_Science') }}</li>
          </ul>
          <strong>
            {{ $t('DataUsage.Integrating_Exterior_Data') }}
          </strong>
          <ul>
            <li>{{ $t('DataUsage.Integrating_Exterior.Routes') }}</li>
            <li>{{ $t('DataUsage.Integrating_Exterior.Shops') }}</li>
          </ul>  
        </section>
      </div> 
    </div>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'DataUse',
  components: {
    MainContain
  },
  data () {
    return {
      datausage_pic0: require('@/assets/images/datausage_0.png'),
      howToUseSportData: '此圖為資料使用說明，包含數據使用角色、數據公益的進行流程;關於角色：包含數據提供者，如個人和企業、數據蒐集者及數據運用者，像是政府機構、學研界和民間單位。關於流程：資料流由個人數據授權開始，個人貢獻或授權數據，配合文件「蒐集個人資料告知提供同意書」。接著是應數據蒐集者，如運動場館、設備業者、活動主辦方、APP業者等，作為廠商的數據收集者需授權數據，讓數據運用者使用。關於數據使用者，須做到兩件事情 1.回饋提升公共利益的分析，像社創化2.產生有助於民眾運動的服務，像社企化.'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.datause-container {
  color: $text_dark;
  font-size: 1rem;
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .content-section{
    padding: 0.625rem;
    .content-desc{
      line-height: 1.5;
      padding: 0.625rem 0;
    }
  }
  li{
    line-height: 1.5;
  }
}
</style>
